











import { Component, Vue } from 'vue-property-decorator';
import { apiGet } from '@/store';

@Component
export default class AdminGeneral extends Vue {
  public updateNotion() {
    apiGet(`/admin/tasks/help/trigger`).then(() => {
      this.$q.notify({
        message: "Les pages d'aides sont en cours de mise à jour",
        type: "positive",
        timeout: 2000,
      });
    });
  }
}
